<template>
  <div class="model-training">
    <UserInfo />
    <div class="header">
      <BackHomeButton />
    </div>
    <div class="title-container">
      <h1 class="title">功能开发中</h1>
      <div class="title-underline"></div>
    </div>
    <!-- <div class="content">
      <div class="content-header">
        <button @click="createNewTask" class="new-task-btn" disabled>新建任务</button>
      </div>
      <div class="task-table">
        <p class="under-construction">该页面正在开发中，敬请期待！</p>
      </div>
    </div> -->
  </div>
</template>
<script>
import axios from 'axios';
import BackHomeButton from '@/components/BackHomeButton.vue';
import UserInfo from '@/components/UserInfo.vue';
export default {
  name: 'ModelTraining',
  components: {
    BackHomeButton,
    UserInfo
  },
  data() {
    return {
      tasks: [],
      isLoading: false,
      error: null
    };
  }
};
</script>
<style scoped>
.model-training {
  padding: 20px;
  width: 90%;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #121212;
}
.header {
  padding: 20px;
  display: flex;
  align-items: center;
}
.title-container {
  text-align: center;
  margin: 40px 0;
  padding: 20px;
}
.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: transparent;
  background: linear-gradient(45deg, #4CAF50, #81C784);
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.title-underline {
  width: 100px;
  height: 4px;
  background: linear-gradient(45deg, #4CAF50, #81C784);
  margin: 0 auto;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.content {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.content-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.new-task-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}
.new-task-btn:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}
.task-table {
  margin-top: 20px;
  background-color: #1E1E1E;
  border-radius: 8px;
  overflow: hidden;
}
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
th {
  background-color: #2D2D2D;
  color: #FFFFFF;
  padding: 16px;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #333;
  font-size: 1.1rem;
}
td {
  padding: 16px;
  border-bottom: 1px solid #333;
  color: #E0E0E0;
  font-size: 1rem;
}
tr:hover {
  background-color: #2A2A2A;
}
.status-badge {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}
.status-badge.pending {
  background-color: #2196F3;
  color: white;
}
.status-badge.processing {
  background-color: #FF9800;
  color: black;
}
.status-badge.completed {
  background-color: #4CAF50;
  color: white;
}
.status-badge.failed {
  background-color: #f44336;
  color: white;
}
.progress-bar {
  width: 100%;
  height: 24px;
  background-color: #2d3748;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  margin: 10px 0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}
.progress {
  height: 100%;
  background: linear-gradient(90deg, #4CAF50, #81C784);
  transition: width 0.3s ease;
  border-radius: 12px;
}
.progress-bar span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.download-btn {
  background-color: #2196F3;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  font-weight: bold;
}
.download-btn:hover {
  background-color: #1976D2;
  transform: translateY(-2px);
}
.under-construction {
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
  color: #E0E0E0;
}
</style>