import { render, staticRenderFns } from "./BuyPoints.vue?vue&type=template&id=5570c7a0&scoped=true"
import script from "./BuyPoints.vue?vue&type=script&lang=js"
export * from "./BuyPoints.vue?vue&type=script&lang=js"
import style0 from "./BuyPoints.vue?vue&type=style&index=0&id=5570c7a0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5570c7a0",
  null
  
)

export default component.exports