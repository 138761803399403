<template>
    <div class="tutorial-container" style="padding-top: 50px;">
      <BackHomeButton /> <!-- 添加返回主页按钮 -->
      <hr class="divider" /> <!-- 添加分割线 -->
      <UserInfo /> <!-- 添加用户信息组件 -->
      
      <div class="tutorial">
        <h2>墨影跳舞工作流教程</h2>
        <iframe src="./vod_dance.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
        <p>包含登录，充值，使用等，请仔细观看！</p>
      </div>
      <div class="tutorial">
        <h2>常见问题1</h2>
        <iframe src="./vod_tocn.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
        <p>当打开的ComfyUI界面是英文，如何切换为中文？</p>
      </div>
      <div class="tutorial">
        <h2>常见问题2</h2>
        <iframe src="./vod_toold.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
        <p>当打开的ComfyUI界面为新版界面，如何切换为旧版？</p>
      </div>
      <!-- 可以继续添加更多视频教程 -->
    </div>
  </template>
  
  <script>
  import BackHomeButton from '@/components/BackHomeButton.vue'; // 导入组件
  import UserInfo from '@/components/UserInfo.vue'; // 导入组件

  export default {
    name: 'MoyingTutorial',
    components: {
      BackHomeButton, // 注册组件
      UserInfo, // 注册组件
    },
    methods: {
      checkAuth() {
        try {
            const token = localStorage.getItem('access_token');
            if (!token) {
            this.$router.push('/login');
            } else {
            this.isLoggedIn = true;
            }
        } catch (error) {
            console.error('检查登录状态时发生错误:', error);
            this.isLoggedIn = false;
        }
      }
    },
    mounted() {
      this.checkAuth(); // 在组件挂载时调用 checkAuth 方法
    }
  };
  </script>
  
  <style scoped>
  .tutorial-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; /* Creates even spacing between grid items */
    justify-content: center;
    align-items: start;
    max-width: 1440px; /* Optional: limit total width */
    margin: 0 auto; /* Center the grid */
    padding-top: 50px; /* 增加顶部间距 */
  }
  
  .tutorial {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .tutorial iframe {
    width: 720px;
    height: 480px;
    max-width: 100%;
    object-fit: contain;
  }
  
  .tutorial h2 {
    margin-bottom: 10px;
  }
  
  .tutorial p {
    margin-top: 10px;
  }

  /* 新增样式以调整按钮和用户信息组件的布局 */
  BackHomeButton, UserInfo {
    grid-column: span 2; /* 使按钮和用户信息组件占据整行 */
    margin-bottom: 20px; /* 添加底部间距 */
    text-align: center; /* 居中对齐 */
  }

  .divider {
    grid-column: span 2; /* 使分割线占据整行 */
    margin: 20px 0; /* 添加上下间距 */
    border: 0.1px solid #ccc; /* 设置分割线样式为1px */
  }
  </style>