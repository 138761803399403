<template>
  <div class="image-upscale">
    <BackHomeButton />
    <h1>JoyCaption Alpha Two</h1>
    <div class="content">
      <div class="left-section">
        <div class="left-section-inner">
          <div class="upload-area" @click="triggerFileInput" @dragover.prevent @drop.prevent="handleDrop">
            <svg v-if="!imagePreview" class="upload-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="17 8 12 3 7 8"></polyline>
              <line x1="12" y1="3" x2="12" y2="15"></line>
            </svg>
            <img v-if="imagePreview" :src="imagePreview" alt="Uploaded image preview" />
            <p v-if="!imagePreview">点击上传或拖放图片</p>
            <p v-if="!imagePreview">PNG, JPG, GIF up to 10MB</p>
            <p>{{ fileName }}</p>
          </div>
          <div class="input-group">
            <select v-model="captionType" class="full-width-input">
              <option v-for="type in captionTypes" :key="type" :value="type">{{ type }}</option>
            </select>
            <select v-model="captionLength" class="full-width-input">
              <option v-for="length in captionLengths" :key="length" :value="length">{{ length }}</option>
            </select>
            <input v-model="characterName" placeholder="人物/角色名称（如适用）" class="full-width-input" />
            <textarea v-model="customPrompt" placeholder="自定义提示（可选，将覆盖其他设置）" class="full-width-input"></textarea>
          </div>
          <div class="extra-options-container">
            <div class="extra-options">
              <h3>额外选项</h3>
              <div class="options-grid">
                <div v-for="option in extraOptions" :key="option" class="option-item">
                  <input type="checkbox" :id="option" v-model="selectedExtraOptions" :value="option">
                  <label :for="option">{{ option }}</label>
                </div>
              </div>
            </div>
          </div>
          <button @click="generateCaption" :disabled="!selectedFile || isGenerating">
            {{ isGenerating ? '生成中...' : '生成描述' }}
          </button>
        </div>
      </div>
      <div class="right-section">
        <textarea v-model="usedPrompt" readonly placeholder="使用的提示将显示在这里" class="used-prompt"></textarea>
        <textarea v-model="generatedCaption" readonly placeholder="生成的描述将显示在这里"></textarea>
        <textarea v-model="chineseCaption" readonly placeholder="生成的描述中文将显示在这里"></textarea>
      </div>
    </div>
    <input type="file" ref="fileInput" @change="handleFileChange" accept="image/*" style="display: none;">
  </div>
</template>

<script>
import axios from 'axios';
import BackHomeButton from '@/components/BackHomeButton.vue';

export default {
  name: 'ImageCaption',
  components: {
    BackHomeButton
  },
  data() {
    return {
      selectedFile: null,
      fileName: '',
      imagePreview: null,
      captionType: '描述',
      captionLength: '长',
      characterName: '',
      customPrompt: '',
      usedPrompt: '',
      generatedCaption: '',
      chineseCaption: '',
      captionTypes: ["描述", "描述 (非正式)", "培训提示", "MidJourney", "Booru标签列表", "Booru-like标签列表", "艺术", "产品列表", "社交帖子"],
      captionLengths: ["任何", "很短", "短", "中等长度", "长", "很长"],
      extraOptions: [
        "如果图像中有一个人/角色，您必须将他们称为{name}",
        "不要包含无法更改的人物/角色信息，但仍包含可更改的属性",
        "包括有关照明信息",
        "包括有关相机角度的信息",
        "包括有关是否存在水印的信息",
        "包括有关是否存在JPEG伪影的信息",
        "如果它是照片,您必须包括有关可能使用的相机和光圈等细节的信息",
        "不要包含任何性内容",
        "不要提及图像的分辨率",
        "必须包含有关图像的主观美学质量的信息，从低到非常高",
        "包括有关图像构图风格的信息,例如引导线,三分法则或对称性",
        "不要提及图像中的任何文本",
        "指定景深以及背景是对焦还是模糊",
        "如果适用，请提及可能使用人工或自然光源",
        "不要使用任何模棱两可的语言",
        "图像是sfw、暗示性还是nsfw",
        "仅描述图像最重要的元素"
      ],
      selectedExtraOptions: [],
      isGenerating: false,
      isLoggedIn: false
    };
  },
  methods: {
    checkAuth() {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          this.$router.push('/login');
        } else {
          this.isLoggedIn = true;
        }
      } catch (error) {
        console.error('检查登录状态时发生错误:', error);
        this.isLoggedIn = false;
        this.$router.push('/login');
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      this.handleFile(event.target.files[0]);
    },
    handleDrop(event) {
      this.handleFile(event.dataTransfer.files[0]);
    },
    handleFile(file) {
      const maxSize = 10 * 1024 * 1024 // 10MB in bytes
      if (file.size > maxSize) {
        alert('图片大小不能超过10MB。')
        return
      }
      if (file.type.startsWith('image/')) {
        this.selectedFile = file
        this.fileName = `Selected: ${file.name}`
        
        const reader = new FileReader()
        reader.onload = (e) => {
          this.imagePreview = e.target.result
        }
        reader.readAsDataURL(file)
      } else {
        alert('Please select an image file.')
      }
    },
    async checkUserPoints() {
      const token = localStorage.getItem('access_token');
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/user-points`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.userPoints = response.data.points;
      } catch (error) {
        console.error('获取用户积分时发生错误:', error);
      }
    },
    async generateCaption() {
      if (!this.selectedFile) {
        alert('Please select an image first.');
        return;
      }

      await this.checkUserPoints();
      if (this.userPoints < 5) {
        this.$router.push('/buy-points');
        return;
      }

      this.isGenerating = true;
      const formData = new FormData();
      formData.append('image', this.selectedFile);
      formData.append('caption_type', this.captionType);
      formData.append('caption_length', this.captionLength);
      formData.append('name_input', this.characterName);
      formData.append('custom_prompt', this.customPrompt);
      formData.append('extra_options', JSON.stringify(this.selectedExtraOptions));

      try {
        this.error = '';
        const token = localStorage.getItem('access_token');
        const response = await axios.post('/api/generate-caption', formData, {
          headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
        });
        this.usedPrompt = response.data.prompt;
        this.generatedCaption = response.data.caption;
        this.chineseCaption = response.data.chinese_caption;
      } catch (error) {
        console.error('Error generating caption:', error);
        if (error.response && error.response.status === 503) {
          alert('正在使用的人太多，服务器繁忙，请稍后再试！');
        } else {
          alert('生成描述时发生错误，请重试。');
        }
      } finally {
        this.isGenerating = false;
      }
    }
  },
  mounted() {
    this.checkAuth();
  }
};
</script>

<style scoped>
.image-upscale {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  color: #e0e0e0;
  font-family: 'Inter', sans-serif;
  background-color: #1a1a1a;
  min-height: 100vh;
}

h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 700;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: start;
}

.left-section {
  background-color: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.left-section-inner {
  padding: 1.5rem;
}

.upload-area {
  width: calc(100% - 3rem);
  height: 250px;
  border: 2px dashed #4a5568;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: #a0aec0;
  margin: 0 1.5rem 1.5rem;
  box-sizing: border-box;
}

.upload-area:hover {
  border-color: #3182ce;
  background-color: rgba(49, 130, 206, 0.1);
}

.upload-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 1rem;
  color: #a0aec0;
}

.upload-area img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.full-width-input {
  width: 100%;
  padding: 0.75rem;
  background-color: #3a3a3a;
  color: #e0e0e0;
  border: 1px solid #4a5568;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.full-width-input:focus {
  border-color: #3182ce;
  outline: none;
}

.extra-options {
  background-color: #3a3a3a;
  border: 1px solid #4a5568;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.extra-options h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #48bb78;
  font-size: 1.25rem;
}

.options-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.option-item {
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  padding: 0.75rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.option-item:hover {
  background-color: #3a3a3a;
}

.option-item input[type="checkbox"] {
  margin-right: 0.75rem;
  flex-shrink: 0;
}

.option-item label {
  font-size: 0.9rem;
  line-height: 1.4;
  color: #e0e0e0;
}

textarea {
  width: 100%;
  height: 150px;
  padding: 0.75rem;
  background-color: #3a3a3a;
  color: #e0e0e0;
  border: 1px solid #4a5568;
  border-radius: 4px;
  resize: vertical;
  font-size: 1rem;
  margin-bottom: 1rem;
  transition: border-color 0.3s ease;
}

textarea:focus {
  border-color: #3182ce;
  outline: none;
}

button {
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover:not(:disabled) {
  background-color: #2c5282;
}

button:disabled {
  background-color: #4a5568;
  cursor: not-allowed;
  opacity: 0.7;
}

@media (max-width: 1024px) {
  .content {
    grid-template-columns: 1fr;
  }
  
  .left-section, .right-section {
    width: 100%;
  }
  
  .options-grid {
    grid-template-columns: 1fr;
  }
}

.used-prompt {
  height: 4em;
  min-height: 4em;
}
</style>
