<template>
  <div class="batch-labeling">
    <UserInfo />
    <div class="header">
      <BackHomeButton />
    </div>
    <div class="title-container">
      <h1 class="title">批量标注任务</h1>
      <div class="title-underline"></div>
    </div>
    <div class="content">
      <div class="content-header">
        <button @click="createNewTask" class="new-task-btn">新建任务</button>
      </div>
      <div class="task-table">
        <table>
          <thead>
            <tr>
              <th>任务ID</th>
              <th>状态</th>
              <th>进度</th>
              <th>开始时间</th>
              <th>结束时间</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="task in tasks" :key="task.id">
              <td>{{ task.task_id }}</td>
              <td>
                <span :class="['status-badge', task.task_status]">
                  {{ getStatusText(task.task_status) }}
                </span>
              </td>
              <td>
                <div class="progress-bar">
                  <div class="progress" :style="{ width: task.task_progress + '%' }"></div>
                  <span>{{ task.task_progress }}%</span>
                </div>
              </td>
              <td>{{ task.start_time }}</td>
              <td>{{ task.end_time }}</td>
              <td>
                <a 
                  v-if="task.task_progress === 100"
                  :href="task.download_url"
                  data-action="Download"
                  class="download-btn"
                  :download="task.task_id + '_result.zip'"
                >
                  下载结果
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import BackHomeButton from '@/components/BackHomeButton.vue';
import UserInfo from '@/components/UserInfo.vue';
export default {
  name: 'BatchLabeling',
  components: {
    BackHomeButton,
    UserInfo
  },
  data() {
    return {
      tasks: [],
      isLoading: false,
      error: null
    };
  },
  methods: {
    async fetchTasks() {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('/api/batch-tasks', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.tasks = response.data;
      } catch (error) {
        console.error('Failed to fetch tasks:', error);
      }
    },
    async createNewTask() {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('/api/user_info', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        // 检查用户积分
        if (response.data.current_points < 600) {
          alert('您的积分不足600分，无法创建新任务');
          this.$router.push('/buy-points');
          return;
        }
        
        // 积分充足，跳转到新建任务页面
        this.$router.push('/batch-labeling/new');
      } catch (error) {
        console.error('Failed to check user points:', error);
        alert('获取用户信息失败，请稍后再试');
      }
    },
    getStatusText(status) {
      const statusMap = {
        'pending': '等待中',
        'processing': '处理中',
        'completed': '已完成',
        'failed': '失败'
      };
      return statusMap[status] || status;
    },
    formatTime(timestamp) {
      if (!timestamp) return '-';
      
      // 直接使用 Date 对象解析 GMT 格式的时间字符串
      const date = new Date(timestamp);
      
      // 获取各个部分
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      // 格式化为 YYYY-MM-DD HH:mm:ss
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async downloadResult(task) {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(task.download_url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Download failed');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const filename = `task_${task.task_id}_result.zip`;

        // Create an anchor element and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // This will force the "Save As" dialog
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Download failed:', error);
        alert('下载失败，请稍后再试');
      }
    }
  },
  mounted() {
    this.fetchTasks();
  }
};
</script>
<style scoped>
.batch-labeling {
  padding: 20px;
  width: 90%;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #121212;
}
.header {
  padding: 20px;
  display: flex;
  align-items: center;
}
.title-container {
  text-align: center;
  margin: 40px 0;
  padding: 20px;
}
.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: transparent;
  background: linear-gradient(45deg, #4CAF50, #81C784);
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.title-underline {
  width: 100px;
  height: 4px;
  background: linear-gradient(45deg, #4CAF50, #81C784);
  margin: 0 auto;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.content {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.content-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.new-task-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}
.new-task-btn:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}
.task-table {
  margin-top: 20px;
  background-color: #1E1E1E;
  border-radius: 8px;
  overflow: hidden;
}
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
th {
  background-color: #2D2D2D;
  color: #FFFFFF;
  padding: 16px;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #333;
  font-size: 1.1rem;
}
td {
  padding: 16px;
  border-bottom: 1px solid #333;
  color: #E0E0E0;
  font-size: 1rem;
}
tr:hover {
  background-color: #2A2A2A;
}
.status-badge {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}
.status-badge.pending {
  background-color: #2196F3;
  color: white;
}
.status-badge.processing {
  background-color: #FF9800;
  color: black;
}
.status-badge.completed {
  background-color: #4CAF50;
  color: white;
}
.status-badge.failed {
  background-color: #f44336;
  color: white;
}
.progress-bar {
  width: 100%;
  height: 24px;
  background-color: #2d3748;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  margin: 10px 0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}
.progress {
  height: 100%;
  background: linear-gradient(90deg, #4CAF50, #81C784);
  transition: width 0.3s ease;
  border-radius: 12px;
}
.progress-bar span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.download-btn {
  background-color: #2196F3;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  font-weight: bold;
}
.download-btn:hover {
  background-color: #1976D2;
  transform: translateY(-2px);
}
</style>